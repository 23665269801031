import { Loader } from '@pinwheel/origami';
import React from 'react';
import { ListWithHeader } from '../../components/layouts/ListWithHeader';
import { SmartBranchRow } from './SmartBranchRow';

type SmartBranchListProps = {
  data: Record<string, string>[];
  loading: boolean;
};

const SmartBranchList: React.FC<SmartBranchListProps> = ({ data, loading }) => {
  if (loading) {
    return <Loader type="circle" />;
  }

  return (
    <React.Fragment>
      <ListWithHeader title="Your Smart Branch URLs" description="">
        {data.length !== 0 && (
          <>
            {data.map((url) => (
              <SmartBranchRow
                key={url.id}
                name={url.name}
                url={url.smartBranchUrl}
                createdAt={new Date(parseInt(url.createdAt))}
              />
            ))}
          </>
        )}
      </ListWithHeader>
    </React.Fragment>
  );
};

export default SmartBranchList;
