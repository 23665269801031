/** @jsxImportSource theme-ui */
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import SmartBranchForm, { TSmartBranchForm } from '../../components/SmartBranch/SmartBranchForm';
import SmartBranchUrl from '../../components/SmartBranch/SmartBranchUrl';
import { useRequiredWorkspace } from '../../hooks/useWorkspace';
import { GetSmartBranchUrl } from '../../graphql/queries/smartBranch/get-smart-branch-url';
import { Loader } from '@pinwheel/origami';
import SmartBranchList from './SmartBranchList';
import { AddSmartBranchUrl } from '../../graphql/mutations/smartBranch/add-smart-branch-url';
import { get } from 'lodash';
import { ListSmartBranchUrls } from '../../graphql/queries/smartBranch/list-smart-branch-urls';

const SmartBranchPage: React.FC = () => {
  const [url, setUrl] = React.useState<string | undefined>();
  const [addUrl] = useMutation(AddSmartBranchUrl);

  const workspace = useRequiredWorkspace();
  const mode = 'production';
  const getBaseUrlResult = useQuery(GetSmartBranchUrl, {
    variables: { mode: mode, workspaceId: workspace.id },
  });
  const listUrlsResult = useQuery(ListSmartBranchUrls);
  if (getBaseUrlResult.loading) {
    return <Loader type="circle" />;
  }

  // Default to sandbox demo page when no workspace smart branch base url is present
  const demo_base_url = 'https://app.getpinwheel.com/smartbranch';
  let smart_branch_url = `${demo_base_url}?p_mode=sandbox`;
  const base_url = getBaseUrlResult.data.getWorkspaceSmartBranchUrl.smartBranchBaseUrl;
  
  const submitFormFn = async (form: TSmartBranchForm) => {
    if (base_url && base_url !== demo_base_url) {
      smart_branch_url = `${base_url}?p_mode=${form.mode}`;
    }
    let result: any = {};
    let error: string = '';
    if (form.email) {
      smart_branch_url += `&p_email=${form.email}`;
    }

    const tags = form.tags === undefined ? {} : JSON.parse(form.tags);
    if (tags === undefined) {
      error = 'Tags JSON is invalid.';
      smart_branch_url = '';
    } else if (!form.name) {
      error = 'Nickname for Smart Branch URL is required.';
      smart_branch_url = '';
    } else {
      for (const prop in tags) {
        if (!/^[a-zA-Z0-9_]+$/.test(tags[prop])) {
          error = 'Tag values cannot contain non-underscore special characters.';
          smart_branch_url = '';
          break;
        }
        smart_branch_url += `&${prop}=${tags[prop]}`;
      }
    }
    result.error = error;
    result.data = { url: smart_branch_url };

    if (smart_branch_url) {
      const addUrlResult = await addUrl({
        variables: {
          name: form.name,
          smartBranchUrl: smart_branch_url,
        },
      });
      const errorMsg = get(addUrlResult, 'errors', null);
      if (errorMsg) {
        result.error = errorMsg;
      }

      listUrlsResult.refetch();
    }

    return result;
  };

  return (
    <React.Fragment>
      <div
        sx={{
          backgroundColor: 'white',
          padding: '3rem 4rem',
          borderRadius: 0,
          boxShadow: 'main',
          marginBottom: '2.5rem',
        }}
      >
        <SmartBranchForm
          submitFormFn={submitFormFn}
          onUrlCreation={(url: string) => {
            setUrl(url);
          }}
        />
        {url && <SmartBranchUrl url={url} />}
      </div>
      <SmartBranchList
        data={listUrlsResult.data?.getSmartBranchUrls}
        loading={listUrlsResult.loading}
      />
    </React.Fragment>
  );
};

export default SmartBranchPage;
